import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'jquery';
import './index.scss';
import LinkInternal from '../linkInternal';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { toggled: false };
        this.setNavWrap = element => {
            this.navWrap = element;
        };
        this.setCloseNavWrap = element => {
            this.closeNavWrap = element;
        };
        this.setMenuToggle = element => {
            this.menuToggle = element;
        };
        this.setSubMenu = element => {
            this.subMenu = element;
        };
        this.setNext = element => {
            this.next = element;
        };
    }

    menuToggleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        document.body.classList.add('nav-wrap-is-visible');
    };

    closeNavWrapClick = e => {
        e.preventDefault();
        e.stopPropagation();
        if (document.body.classList.contains('nav-wrap-is-visible')) {
            document.body.classList.remove('nav-wrap-is-visible');
        }
        this.setState({ toggled: false });
    };

    toggle = e => {
        e.preventDefault();
        this.setState({ toggled: !this.state.toggled });
    };

    renderNav = (el, i) => {
        const showSubMenu = this.state.toggled ? 'sub-menu-is-open' : '';
        const showNextMenu = this.state.toggled ? 'sub-menu-opened' : '';

        if (el.title === 'Home') {
            return (
                <li className='current' key={i}>
                    <LinkInternal to={el.link} value={el.title} />
                    {/* <a href={el.link} title=''>
                        {el.title}
                    </a> */}
                </li>
            );
        } else if (el.dropdown) {
            return (
                <li className='has-children' key={i}>
                    <a
                        href='#0'
                        title=''
                        onClick={this.toggle}
                        ref={this.setSubMenu}
                        className={`${showSubMenu}`}>
                        {el.title}
                    </a>
                    <ul
                        className={`sub-menu ${showNextMenu}`}
                        ref={this.setNext}
                        style={this.state.style}>
                        {el.dropdown.map((item, i) => (
                            <li key={i}>
                                <LinkInternal to={item.link} value={item.title}/>
                                {/* <a href={item.link}>{item.title}</a> */}
                            </li>
                        ))}
                    </ul>
                </li>
            );
        } else {
            return (
                <li key={i}>
                    <a href={el.link} title=''>
                        {el.title}
                    </a>
                </li>
            );
        }
    };

    render() {
        const { toggle, heading, nav } = this.props.data;
        const Aux = props => props.children;

        // open (or close) submenu items in mobile view menu.
        // close all the other open submenu items.

        return (
            <Aux>
                <a
                    className='header__toggle-menu'
                    href='#0'
                    title='Menu'
                    ref={this.setMenuToggle}
                    onClick={this.menuToggleClick}>
                    <span>{toggle}</span>
                </a>
                <nav className='header__nav-wrap' ref={this.setNavWrap}>
                    <h2 className='header__nav-heading h6'>{heading}</h2>

                    <ul className='header__nav'>
                        {nav.map((element, i) => this.renderNav(element, i))}
                    </ul>
                    {/* <!-- end header__nav --> */}

                    <a
                        href='#0'
                        title='Close Menu'
                        className='header__overlay-close close-mobile-menu'
                        ref={this.setCloseNavWrap}
                        onClick={this.closeNavWrapClick}>
                        Close
                    </a>
                    {/* <!-- end header__nav-wrap --> */}
                </nav>
            </Aux>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.navbar[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);
