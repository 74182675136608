import React, { Component } from 'react';
import { connect } from 'react-redux';


class VideoBrick extends Component {
    render() {
        const { content } = this.props.data;
        const video = content.video;
        // let srcSetArray = [content.srcSet.map(src => require(src))]
        const srcSet = video.img.srcSet.join(', '); // srcSetArray.join(', ')
        return (
            <article
                className='masonry__brick entry format-video'
                data-aos='fade-up'>
                <div className='entry__thumb video-image'>
                    <a href={content.video.link} data-lity>
                        <img
                            src={require(`${video.img.src}`)}
                            srcSet={srcSet}
                            alt={video.img.alt}
                        />
                    </a>
                </div>

                <div className='entry__text'>
                    <div className='entry__header'>
                        <div className='entry__date'>
                            <a href={content.blogLink}>{content.date}</a>
                        </div>
                        <h1 className='entry__title'>
                            <a href={content.blogLink}>{content.title}</a>
                        </h1>
                    </div>
                    <div className='entry__excerpt'>
                        <p>{content.summary}</p>
                    </div>
                    <div className='entry__meta'>
                        <span className='entry__meta-links'>
                            {content.categories.map((item, i) => {
                                return (
                                    <a href={item.link} key={i}>
                                        {item.category}
                                    </a>
                                );
                            })}
                        </span>
                    </div>
                </div>
                {/* End Article*/}
            </article>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.videoBrick[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoBrick);
