import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Masonry from 'react-masonry-component';

// const masonryOptions = {
//     transitionDuration: 0
// };

class SContent extends Component {
    // entryContent = (entry, i) => {
    //     if (entry.article === 'Standard') {
    //         return <StandardBrick key={i} content={entry.content} />;
    //     } else if (entry.article === 'Video') {
    //         return <VideoBrick key={i} content={entry.content} />;
    //     } else if (entry.article === 'Quote') {
    //         return <QuoteBrick key={i} content={entry.content} />;
    //     } else if (entry.article === 'Gallery') {
    //         return <GalleryBrick key={i} content={entry.content} />;
    //     } else {
    //         return <div key={i} />;
    //     }
    // };

    render() {
        // const { entries } = this.props.data;
        return (
            <section className='s-content'>
                {this.props.data.instances.map(mapObjectToComponent)}
            </section>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.sContent[passed.id]
    };
};

export default connect(mapStateToProps)(SContent);
