import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkInternal from '../linkInternal';

class AudioBrick extends Component {
	render() {
		const { content } = this.props.data;
		const img = content.img;
		const audio = content.audio;
		// const audio = content.audio;
		// let srcSetArray = [content.srcSet.map(src => require(src))]
		const srcSet = content.img.srcSet.join(', '); // srcSetArray.join(', ')
		return (
			<article className="masonry__brick entry format-audio" data-aos="fade-up">
				<div className="entry__thumb">
					<a href="#0" className="entry__thumb-link">
						<img src={require(`${img.src}`)} srcSet={srcSet} alt={img.alt} />
					</a>
					<div className="audio-wrap">
						<audio
							id={audio.id}
							src={require(`${audio.src}`)}
							width={audio.width}
							height={audio.height}
							controls={audio.controls}
						/>
					</div>
				</div>
				<div className="entry__text">
					<div className="entry__header">
						<div className="entry__date">
							<a href={content.blogLink}>{content.date}</a>
						</div>
						<h1 className="entry__title">
							{/* <a href={content.blogLink}>{content.title}</a> */}
							<LinkInternal to={content.blogLink} value={content.title} />
						</h1>
					</div>
					<div className="entry__excerpt">
						<p>{content.summary}</p>
					</div>
					<div className="entry__meta">
						<span className="entry__meta-links">
							{content.categories.map((item, i) => {
								return (
									<a href={item.link} key={i}>
										{item.category}
									</a>
								);
							})}
						</span>
					</div>
				</div>
				{/* End Article*/}
			</article>
		);
	}
}

const mapStateToProps = (state, passed) => {
	return {
		data: state.content.audioBrick[passed.id]
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioBrick);
