import React, { Component } from 'react';
import { connect } from 'react-redux';
import BlogContent from 'store/components/blogContent';
import './index.scss';
import LinkInternal from '../linkInternal';

class BlogStandard extends Component {
    render() {
        const {
            title,
            date,
            categories,
            img,
            content,
            headshot,
            name,
            social,
            summary,
            about
        } = this.props.data;
        // let srcSetArray = [content.srcSet.map(src => require(src))]
        // const img = content.img; // srcSetArray.join(', ')
        const srcSets = img.srcSet;
        const setImages = srcSets.map(
            set => require(`${set.src}`) + ' ' + set.attr
        );
        const srcSet = setImages.join(', ');
        // eslint-disable-next-line
        const cont = content;
        return (
            <section className='s-content s-content--narrow s-content--no-padding-bottom'>
                <article className='row format-standard'>
                    <div className='s-content__header col-full'>
                        <h1 className='s-content__header-title'>{title}</h1>
                        <ul className='s-content__header-meta'>
                            <li className='date' style={{ color: '#656565' }}>
                                {date}
                            </li>
                            <li className='cat'>
                                <span style={{ color: '#656565' }}>
                                    {'In '}
                                </span>
                                {categories.map((category, i) => (
                                    <LinkInternal
                                        to={category.link}
                                        value={' ' + category.tag}
                                        key={i}
                                    />
                                ))}
                            </li>
                        </ul>
                    </div>
                    <div className='s-content__media col-full'>
                        <div className='s-content__post-thumb'>
                            <img
                                src={require(`${img.src}`)}
                                srcSet={srcSet}
                                sizes={img.sizes}
                                alt={img.alt}
                            />
                        </div>
                    </div>
                    <div className='col-full s-content__main'>
                        <p className='lead description'>{img.description}</p>
                        <div className='contentFormat'>
                            {/* <h3>Paragraph and Image</h3>

                            <p>
                                <a href='#0'>
                                    <img
                                        width='120'
                                        height='120'
                                        className='pull-left'
                                        alt='sample'
                                        src='images/sample-image.jpg'
                                    />
                                </a>
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit. Donec libero. Suspendisse
                                bibendum.Cras id urna. Morbi tincidunt, orci ac
                                convallis aliquam, lectus turpis varius lorem,
                                eu posuere nunc justo tempus leo. Donec mattis,
                                purus nec placerat bibendum, dui pede
                                condimentum odio, ac blandit ante orci ut diam.
                                Cras fringilla magna. Phasellus suscipit, leo a
                                pharetra condimentum, lorem tellus eleifend
                                magna, eget fringilla velit magna id neque
                                posuere nunc justo tempus leo.{' '}
                            </p>

                            <p>
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit. Donec libero. Suspendisse
                                bibendum. Cras id urna. Morbi tincidunt, orci ac
                                convallis aliquam, lectus turpis varius lorem,
                                eu posuere nunc justo tempus leo. Donec mattis,
                                purus nec placerat bibendum, dui pede
                                condimentumodio, ac blandit ante orci ut diam.
                            </p>

                            <p>
                                A <a href='#0'>link</a>,
                                <abbr title="this really isn't a very good description">
                                    abbrebation
                                </abbr>
                                ,<strong>strong text</strong>,<em>em text</em>,
                                <del>deleted text</del>, and
                                <mark>this is a mark text.</mark>
                                <code>.code</code>
                            </p>
                            <h2>Drop Caps</h2>

                            <p className='drop-cap'>
                                Far far away, behind the word mountains, far
                                from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live
                                in Bookmarksgrove right at the coast of the
                                Semantics, a large language ocean. A small river
                                named Duden flows by their place and supplies it
                                with the necessary regelialia. Morbi tincidunt,
                                orci ac convallis aliquam, lectus turpis varius
                                lorem, eu posuere nunc justo tempus leo. Donec
                                mattis, purus nec placerat bibendum, dui pede
                                condimentum odio, ac blandit ante orci ut diam.
                                Cras fringilla magna. Phasellus suscipit, leo a
                                pharetra condimentum, lorem tellus eleifend
                                magna, eget fringilla velit magna id neque.
                            </p>

                            <h3>Small Print</h3>

                            <p>
                                Buy one widget, get one free!
                                <small>
                                    (While supplies last. Offer expires on the
                                    vernal equinox. Not valid in Ohio.)
                                </small>
                            </p>
                            <h3>Pull Quotes</h3>

                            <aside className='pull-quote'>
                                <blockquote>
                                    <p>
                                        It is a paradisematic country, in which
                                        roasted parts of sentences fly into your
                                        mouth. Even the all-powerful Pointing
                                        has no control about the blind texts it
                                        is an almost unorthographic life One day
                                        however a small line of blind text by
                                        the name of Lorem Ipsum decided to leave
                                        for the far World of Grammar.
                                    </p>
                                </blockquote>
                            </aside>
                            <h3>Block Quotes</h3>

                            <blockquote cite='http://where-i-got-my-info-from.com'>
                                <p>
                                    Your work is going to fill a large part of
                                    your life, and the only way to be truly
                                    satisfied is to do what you believe is great
                                    work. And the only way to do great work is
                                    to love what you do. If you haven't found it
                                    yet, keep looking. Don't settle. As with all
                                    matters of the heart, you'll know when you
                                    find it.
                                </p>
                                <cite>
                                    <a href='#0'>Steve Jobs</a>
                                </cite>
                            </blockquote>

                            <blockquote>
                                <p>
                                    Good design is as little design as possible.
                                </p>
                                <cite>Dieter Rams</cite>
                            </blockquote>
                            <h3>Example Lists</h3>

                            <ol>
                                <li>Here is an example</li>
                                <li>of an ordered list.</li>
                                <li>
                                    A parent list item.
                                    <ul>
                                        <li>one</li>
                                        <li>two</li>
                                        <li>three</li>
                                    </ul>
                                </li>
                                <li>A list item.</li>
                            </ol>

                            <ul className='disc'>
                                <li>Here is an example</li>
                                <li>of an unordered list.</li>
                            </ul>

                            <h3>Definition Lists</h3>

                            <h5>a) Multi-line Definitions (default) </h5>

                            <dl>
                                <dt>
                                    <strong>This is a term</strong>
                                </dt>
                                <dd>
                                    this is the definition of that term, which
                                    both live in a <code>dl</code>.
                                </dd>
                                <dt>
                                    <strong>Another Term</strong>
                                </dt>
                                <dd>
                                    And it gets a definition too, which is this
                                    line
                                </dd>
                                <dd>
                                    This is a 2<sup>nd</sup> definition for a
                                    single term. A <code>dt</code> may be
                                    followed by multiple <code>dd</code>s.
                                </dd>
                            </dl>

                            <h2>H2 Heading</h2>
                            <p>
                                Doloremque dolor voluptas est sequi omnis.
                                Pariatur ut aut. Sed enim tempora qui veniam qui
                                cum vel. Voluptas odit at vitae minima. In
                                assumenda ut. Voluptatem totam impedit
                                accusantium reiciendis excepturi aut qui
                                accusamus praesentium.
                            </p>

                            <h3>H3 Heading</h3>
                            <p>
                                Doloremque dolor voluptas est sequi omnis.
                                Pariatur ut aut. Sed enim tempora qui veniam qui
                                cum vel. Voluptas odit at vitae minima. In
                                assumenda ut. Voluptatem totam impedit
                                accusantium reiciendis excepturi aut qui
                                accusamus praesentium.
                            </p>
                            <h4>H4 Heading</h4>
                            <p>
                                Doloremque dolor voluptas est sequi omnis.
                                Pariatur ut aut. Sed enim tempora qui veniam qui
                                cum vel. Voluptas odit at vitae minima. In
                                assumenda ut. Voluptatem totam impedit
                                accusantium reiciendis excepturi aut qui
                                accusamus praesentium.
                            </p>

                            <h5>H5 Heading</h5>
                            <p>
                                Doloremque dolor voluptas est sequi omnis.
                                Pariatur ut aut. Sed enim tempora qui veniam qui
                                cum vel. Voluptas odit at vitae minima. In
                                assumenda ut. Voluptatem totam impedit
                                accusantium reiciendis excepturi aut qui
                                accusamus praesentium.
                            </p>

                            <h6>H6 Heading</h6>
                            <p>
                                Doloremque dolor voluptas est sequi omnis.
                                Pariatur ut aut. Sed enim tempora qui veniam qui
                                cum vel. Voluptas odit at vitae minima. In
                                assumenda ut. Voluptatem totam impedit
                                accusantium reiciendis excepturi aut qui
                                accusamus praesentium.
                            </p>

                            <h3>Stats Tabs</h3>

                            <ul className='stats-tabs'>
                                <li>
                                    <a href='#0'>
                                        1,234 <em>Sasuke</em>
                                    </a>
                                </li>
                                <li>
                                    <a href='#0'>
                                        567 <em>Hinata</em>
                                    </a>
                                </li>
                                <li>
                                    <a href='#0'>
                                        23,456 <em>Naruto</em>
                                    </a>
                                </li>
                                <li>
                                    <a href='#0'>
                                        3,456 <em>Kiba</em>
                                    </a>
                                </li>
                                <li>
                                    <a href='#0'>
                                        456 <em>Shikamaru</em>
                                    </a>
                                </li>
                                <li>
                                    <a href='#0'>
                                        26 <em>Sakura</em>
                                    </a>
                                </li>
                            </ul>
                            <h3>Tables</h3>
                            <p>
                                Be sure to use properly formed table markup with{' '}
                                <code>&lt;thead&gt;</code> and{' '}
                                <code>&lt;tbody&gt;</code> when building a{' '}
                                <code>table</code>.
                            </p>

                            <div className='table-responsive'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Age</th>
                                            <th>Sex</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Naruto Uzumaki</td>
                                            <td>16</td>
                                            <td>Male</td>
                                            <td>Konoha</td>
                                        </tr>
                                        <tr>
                                            <td>Sakura Haruno</td>
                                            <td>16</td>
                                            <td>Female</td>
                                            <td>Konoha</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            <BlogContent content={cont} />
                            <p className='s-content__tags'>
                                <span>Categories</span>
                                <span className='s-content__tag-list'>
                                    {categories.map((category, i) => (
                                        <LinkInternal
                                            to={category.link}
                                            value={' ' + category.tag}
                                            key={i}
                                        />
                                    ))}
                                </span>
                            </p>
                            <div className='s-content__author'>
                                <img
                                    src={require(`${headshot.src}`)}
                                    alt={headshot.alt}
                                />
                                <div className='s-content__author-about'>
                                    <h4 className='s-content__author-name'>
                                        <LinkInternal to={about} value={name}></LinkInternal>
                                    </h4>
                                    <p>{summary}</p>
                                    <ul className='s-content__author-social'>
                                        {social.map((media, i) => {
                                            return (
                                                <li key={i}>
                                                    <LinkInternal
                                                        to={media.link}
                                                        value={media.social}
                                                    />

                                                    {/* <a href={media.link}>
                                    {media.social}
                                </a> */}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.blogStandard[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogStandard);
