import React, { Component } from 'react';
import { connect } from 'react-redux';

class Preloader extends Component {
    render() {
        return (
            <div id='preloader'>
                <div id='loader'>
                    <div className='line-scale'>
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.preloader[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preloader);
