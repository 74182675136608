import React, { Component } from 'react';
import FeaturedEntry from 'store/components/featuredEntry'
import { connect } from 'react-redux';

class FeaturedBig extends Component {
    render() {
        const entries = this.props.data;
        return (
                <div className='featured__column featured__column--big'>
                    {entries.map((entry, i) => {
                        return(<FeaturedEntry key={i}
                            bgimg={entry.bgimg} 
                            category={entry.category} 
                            categoryLink={entry.categoryLink} 
                            title={entry.title.toString()} 
                            titleLink={entry.titleLink} 
                            entryInfo={entry.entryInfo} 
                            entryMeta={entry.entryMeta} />)
                    })}
                    {/* end featured__big */}
                </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.featuredBig[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeaturedBig);
