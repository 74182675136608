import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkInternal from '../linkInternal';

class StandardBrick extends Component {
    render() {
        const { content } = this.props.data;
        // let srcSetArray = [content.srcSet.map(src => require(src))]
        const cimg = content.img; // srcSetArray.join(', ')
        const srcSets = cimg.srcSet;
        const setImages = srcSets.map(set => {
            return require(`${set.srcs}`) + ' ' + set.attr;
        });
        const srcSet = setImages.join(', ');
        const imgSrc = require(`${cimg.src}`);
        const image = <img src={imgSrc} srcSet={srcSet} alt={cimg.alt} />;
        return (
            <article
                className='masonry__brick entry format-standard'
                data-aos='fade-up'>
                <div className='entry__thumb'>
                    <LinkInternal
                        to={content.blogLink}
                        className='entry__thumb-link'
                        value={image}
                    />
                    {/* <a href={content.blogLink} className='entry__thumb-link'>
                        <img
                            src={require(`${content.img.src}`)}
                            srcSet={srcSet}
                            alt={content.img.alt}
                        />
                    </a> */}
                </div>
                <div className='entry__text'>
                    <div className='entry__header'>
                        <div className='entry__date'>
                            <a href='single-standard.html'>{content.date}</a>
                        </div>
                        <h1 className='entry__title'>
                            <LinkInternal
                                to={content.blogLink}
                                value={content.title}
                            />
                            {/* <a href={content.blogLink}>{content.title}</a> */}
                        </h1>
                    </div>
                    <div className='entry__excerpt'>
                        <p>{content.summary}</p>
                    </div>
                    <div className='entry__meta'>
                        <span className='entry__meta-links'>
                            {content.categories.map((item, i) => {
                                return (
                                    <><LinkInternal to={item.link} value={item.category} key={i} />

                                    {/* <a href={item.link} key={i}>{item.category}</a> */}
                                    </>
                                );
                            })}
                        </span>
                    </div>
                </div>
                {/* end article */}
            </article>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.standardBrick[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StandardBrick);
