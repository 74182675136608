import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Logo from 'store/components/logo';
// import HeaderSocial from 'store/components/headerSocial';
// import Navbar from 'store/components/navbar';

class EightColumnPopular extends Component {
    render() {
        // const { logo, headerSocial, headerSearch, navbar } = this.props.data;
        const { title } = this.props.data;
        return (
            <div className='col-eight md-six tab-full popular'>
                <h3>{title}</h3>
                <div className='block-1-2 block-m-full popular__posts'>
                    {this.props.data.instances.map(mapObjectToComponent)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.eightColumnPopular[passed.id]
    };
};

export default connect(mapStateToProps)(EightColumnPopular);
