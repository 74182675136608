import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkInternal from '../linkInternal';

class Logo extends Component {
    render() {
        const {link,img} = this.props.data;
        return (
            <div className="header__logo">
                <LinkInternal to={link} className="logo" value={<img src={require(`${img.src}`)} alt={img.alt}/>} />
                    {/* <a className="logo" href={link}>
                        <img src={require(`${img.src}`)} alt={img.alt}/>
                    </a> */}
                    {/* <!-- end header__logo --> */}
            </div> 
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.logo[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logo);
