import React, { Component } from 'react';
import './App.scss';
import Content from 'store/components/content';

class App extends Component {
	render() {
		return (
			<div id="App">
				<Content />
			</div>
		);
	}
}

export default App;
