import React, { Component } from 'react';
import { connect } from 'react-redux';

class QuoteBrick extends Component {
    render() {
        const { content } = this.props.data;
        return (
            <article
                className='masonry__brick entry format-quote'
                data-aos='fade-up'>
                <div className='entry__thumb'>
                    <blockquote>
                        <p>
                            {content.quote}
                        </p>
                        <cite>{content.cite}</cite>
                    </blockquote>
                </div>
                {/* end article */}
            </article>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.quoteBrick[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuoteBrick);