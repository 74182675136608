import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Logo from 'store/components/logo';
// import HeaderSocial from 'store/components/headerSocial';
// import Navbar from 'store/components/navbar';

class BottomRow extends Component {
    render() {
        // const { logo, headerSocial, headerSearch, navbar } = this.props.data;
        return (
                <div className='row bottom tags-wrap'>
                    {this.props.data.instances.map(mapObjectToComponent)}
                </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.bottomRow[passed.id]
    };
};

export default connect(mapStateToProps)(BottomRow);
