import React, { Component } from 'react';
import { connect } from 'react-redux';

class LinkBrick extends Component {
    render() {
        const { content } = this.props.data;
        return (
            <article
                className='masonry__brick entry format-link'
                data-aos='fade-up'>
                <div className='entry__thumb'>
                <div className="link-wrap">
                            <p>{content.text}</p>
                            <cite>
                                <a target="_blank" rel="noopener noreferrer" href={content.link}>{content.display}</a>
                            </cite>
                        </div>
                </div>
                {/* End Article*/}
            </article>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.linkBrick[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkBrick);