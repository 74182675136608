import React from 'react';
import HomeHero from 'store/components/homeHero';
import Preloader from 'store/components/preloader';
import PageHeader from 'store/components/pageHeader';
import SContent from 'store/components/sContent';
import Category from 'store/components/category';
import Articles from 'store/components/articles';
import Header from 'store/components/header';
import HeaderSearch from 'store/components/headerSearch';
import Logo from 'store/components/logo';
import HeaderSocial from 'store/components/headerSocial';
import Navbar from 'store/components/navbar';
import PageHeaderContent from 'store/components/pageHeaderContent';
import FeaturedBig from 'store/components/featuredBig';
import FeaturedSmall from 'store/components/featuredSmall';
import StandardBrick from 'store/components/standardBrick';
import VideoBrick from 'store/components/videoBrick';
import QuoteBrick from 'store/components/quoteBrick';
import GalleryBrick from 'store/components/galleryBrick';
import AudioBrick from 'store/components/audioBrick';
import LinkBrick from 'store/components/linkBrick';
import SExtra from 'store/components/sExtra';
import TopRow from 'store/components/topRow';
import BottomRow from 'store/components/bottomRow';
import EightColumnPopular from 'store/components/eightColumnPopular';
import PopularPost from 'store/components/popularPost';
import AboutSnippet from 'store/components/aboutSnippet';
import BottomTags from 'store/components/bottomTags';
import Footer from 'store/components/footer';
import FooterTop from 'store/components/footerTop';
import FooterLinks from 'store/components/footerLinks';
import FooterSubscribe from 'store/components/footerSubscribe';
import FooterBottom from 'store/components/footerBottom';
import FooterCopyright from 'store/components/footerCopyright';
import FooterGoToTop from 'store/components/footerGoToTop';
import BlogStandard from 'store/components/blogStandard';
import About from 'store/components/about';


// import TermsMarketplacePage from 'component/plainHTMLpages/terms-individual.js';

const mapObjectToComponent = (instance, i) => {
    const { component, id } = instance;

    switch (component) {
        // for Homepage
        case 'homeHero':
            return <HomeHero key={i} id={id} />;
        case 'preloader':
            return <Preloader key={i} id={id} />;
        case 'pageHeader':
            return <PageHeader key={i} id={id} />;
        case 'header':
            return <Header key={i} id={id} />;
        case 'pageHeaderContent':
            return <PageHeaderContent key={i} id={id} />;
        case 'sContent':
            return <SContent key={i} id={id} />;
        case 'category':
            return <Category key={i} id={id} />;
        case 'articles':
            return <Articles key={i} id={id} />;
        case 'headerSearch':
            return <HeaderSearch key={i} id={id} />;
        case 'logo':
            return <Logo key={i} id={id} />;
        case 'headerSocial':
            return <HeaderSocial key={i} id={id} />;
        case 'navbar':
            return <Navbar key={i} id={id} />;
        case 'featuredBig':
            return <FeaturedBig key={i} id={id} />;
        case 'featuredSmall':
            return <FeaturedSmall key={i} id={id} />;
        case 'standardBrick':
            return <StandardBrick key={i} id={id} />;
        case 'videoBrick':
            return <VideoBrick key={i} id={id} />;
        case 'quoteBrick':
            return <QuoteBrick key={i} id={id} />;
        case 'galleryBrick':
            return <GalleryBrick key={i} id={id} />;
        case 'audioBrick':
            return <AudioBrick key={i} id={id} />;
        case 'linkBrick':
            return <LinkBrick key={i} id={id} />;
        case 'sExtra':
            return <SExtra key={i} id={id} />;
        case 'topRow':
            return <TopRow key={i} id={id} />;
        case 'bottomRow':
            return <BottomRow key={i} id={id} />;
        case 'eightColumnPopular':
            return <EightColumnPopular key={i} id={id} />;
        case 'popularPost':
            return <PopularPost key={i} id={id} />;
        case 'aboutSnippet':
            return <AboutSnippet key={i} id={id} />;
        case 'bottomTags':
            return <BottomTags key={i} id={id} />;
        case 'footer':
            return <Footer key={i} id={id} />;
        case 'footerTop':
            return <FooterTop key={i} id={id} />;
        case 'footerLinks':
            return <FooterLinks key={i} id={id} />;
        case 'footerSubscribe':
            return <FooterSubscribe key={i} id={id} />;
        case 'footerBottom':
            return <FooterBottom key={i} id={id} />;
        case 'footerCopyright':
            return <FooterCopyright key={i} id={id} />;
        case 'footerGoToTop':
            return <FooterGoToTop key={i} id={id} />;
        case 'blogStandard':
            return <BlogStandard key={i} id={id} />;
        case 'about':
            return <About key={i} id={id} />;
        default:
            return <div key={i} id='incorrectInput' />;
    }
};
export default mapObjectToComponent;
