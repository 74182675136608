import React, { Component } from 'react';
import { connect } from 'react-redux';
// import SlickSlider from 'store/components/slickSlider';

class GalleryBrick extends Component {
    render() {
        const { content } = this.props.data;
        // let srcSetArray = [content.srcSet.map(src => require(src))]
        return (
            <article
                className='masonry__brick entry format-gallery'
                data-aos='fade-up'>
                <div className='entry__thumb slider'>
                    {/* <SlickSlider galleryPics={content.galleryPics} /> */}
                    <div className='slider__slides'>
                        {content.galleryPics.map((image, i) => {
                            return (
                                <div className='slider__slide' key={i}>
                                    <img
                                        src={require(`${image.src}`)}
                                        srcSet={image.srcSet.join(', ')}
                                        alt={image.alt}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='entry__text'>
                    <div className='entry__header'>
                        <div className='entry__date'>
                            <a href='single-standard.html'>{content.date}</a>
                        </div>
                        <h1 className='entry__title'>
                            <a href={content.blogLink}>{content.title}</a>
                        </h1>
                    </div>
                    <div className='entry__excerpt'>
                        <p>{content.summary}</p>
                    </div>
                    <div className='entry__meta'>
                        <span className='entry__meta-links'>
                            {content.categories.map((item, i) => {
                                return (
                                    <a href={item.link} key={i}>
                                        {item.category}
                                    </a>
                                );
                            })}
                        </span>
                    </div>
                </div>
                {/* end article */}
            </article>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.galleryBrick[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GalleryBrick);
