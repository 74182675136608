import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkInternal from '../linkInternal';

class FooterLinks extends Component {
    render() {
        const { title, classID, linkList } = this.props.data;
        return (
            <div className={`col-two md-four mob-full s-footer__${classID}`}>
                <h4>{title}</h4>

                <ul className='s-footer__linklist'>
                    {linkList.map((link,i) => {
                        return(
                            <li key={i}>
                                <LinkInternal to={link.link} value={link.name}></LinkInternal>
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.footerLinks[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterLinks);
