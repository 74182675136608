import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';

class About extends Component {
    render() {
        const { blocks, title, leadDescription, contents, img } = this.props.data;
        return (
            <section className='s-content s-content--narrow'>
                <div className='row'>
                    <div className='s-content__header col-full'>
                        <h1 className='s-content__header-title'>{title}</h1>
                    </div>

                    <div className='s-content__media col-full'>
                        <div className='s-content__post-thumb'>
                            <img
                                src={require(`${img.src}`)}
                                alt={img.alt}
                                sizes="(max-width: 2000px) 100vw, 2000px"
                                id="profilepic"
                                className="img-fluid rounded mx-auto"
                            />
                        </div>
                    </div>

                    <div className='col-full s-content__main'>
                        <p className='lead description-title'>
                            {leadDescription}
                        </p>
                        <div className='description-content'>
                            {contents.map((content, i) => (
                                <p key={i}>{content.description}</p>
                            ))}
                        </div>

                        <div className='row block-1-2 block-tab-full'>
                            {blocks.map((block, i) => (
                                <div className='col-block' key={i}>
                                    <h3 className='quarter-top-margin title'>
                                        {block.title}
                                    </h3>
                                    <div className='description-content'>
                                        <p>{block.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.about[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
