import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkInternal from 'store/components/linkInternal';

class AboutSnippet extends Component {
    render() {
        const { title, summary, social } = this.props.data;
        return (
            <div className='col-four md-six tab-full about'>
                <h3>{title}</h3>
                <p>{summary}</p>
                <ul className='about__social'>
                    {social.map((media, i) => {
                        return (
                            <li key={i}>
                                <a href={media.link} target="_blank">
                                        <i
                                            className={`fa ${
                                                media.classSocial
                                            }`}
                                            aria-hidden='true'
                                        />
                                </a>
                                {/* <a href={media.link}>
                                    <i
                                        className={`fa ${media.classSocial}`}
                                        aria-hidden='true'
                                    />
                                </a> */}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.aboutSnippet[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutSnippet);
